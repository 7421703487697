import { graphql, useStaticQuery } from "gatsby"
import { FixedObject } from "gatsby-image"
import React from "react"
import LogosSlider from "../../LogosSlider"

const PresseLogos: React.FunctionComponent = () => {
  const data = useStaticQuery(graphql`
    query {
      vingtminutes: file(relativePath: { eq: "presse/logos/20minutes.png" }) {
        childImageSharp {
          fixed(height: 40) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      challenges: file(relativePath: { eq: "presse/logos/challenges.png" }) {
        childImageSharp {
          fixed(height: 40) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      elle: file(relativePath: { eq: "presse/logos/elle.png" }) {
        childImageSharp {
          fixed(height: 40) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      figaro: file(relativePath: { eq: "presse/logos/figaro.png" }) {
        childImageSharp {
          fixed(height: 40) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      france24: file(relativePath: { eq: "presse/logos/france24.png" }) {
        childImageSharp {
          fixed(height: 60) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      neorestauration: file(
        relativePath: { eq: "presse/logos/neo-restauration.png" }
      ) {
        childImageSharp {
          fixed(height: 60) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      parisien: file(relativePath: { eq: "presse/logos/parisien.png" }) {
        childImageSharp {
          fixed(height: 40) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      restauration21: file(
        relativePath: { eq: "presse/logos/restauration21.jpg" }
      ) {
        childImageSharp {
          fixed(height: 40) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }

      snacking: file(relativePath: { eq: "presse/logos/snacking.png" }) {
        childImageSharp {
          fixed(height: 40) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)
  const logos = [
    {
      height: 50,
      fluidObject: data.vingtminutes.childImageSharp.fixed,
      alt: "20 minutes",
    },
    {
      height: 50,
      fluidObject: data.challenges.childImageSharp.fixed,
      alt: "Challenges",
    },
    { height: 30, fluidObject: data.elle.childImageSharp.fixed, alt: "Elle" },
    {
      height: 40,
      fluidObject: data.figaro.childImageSharp.fixed,
      alt: "Le Figaro",
    },
    {
      height: 50,
      fluidObject: data.france24.childImageSharp.fixed,
      alt: "France24",
    },
    {
      height: 40,
      fluidObject: data.parisien.childImageSharp.fixed,
      alt: "Le Parisien",
    },
    {
      height: 60,
      fluidObject: data.neorestauration.childImageSharp.fixed,
      alt: "Neo-restauration",
    },
    {
      height: 50,
      fluidObject: data.restauration21.childImageSharp.fixed,
      alt: "Restauration21",
    },
    {
      height: 50,
      fluidObject: data.snacking.childImageSharp.fixed,
      alt: "Snacking",
    },
  ]
  return <LogosSlider logos={logos} />
}

export default PresseLogos
