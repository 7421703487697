import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"

import "./PressReleases.scss"

const PressReleases: React.FunctionComponent = () => {
  const pressReleases: {
    allMdx: {
      nodes: {
        id: string
        fields: { slug: string }
        frontmatter: { title: string; date: string }
      }[]
    }
  } = useStaticQuery(graphql`
    {
      allMdx(
        limit: 6
        sort: { fields: frontmatter___date, order: DESC }
        filter: { frontmatter: { templateKey: { eq: "CommuniquePresse" } } }
      ) {
        nodes {
          id
          fields {
            slug
          }
          frontmatter {
            title
            date(fromNow: true, locale: "FR")
          }
        }
      }
    }
  `)
  return (
    <section className="press-releases section-padding">
      <div className="container">
        <h2 className="heading heading--section-title">
          Communiqués de presse
        </h2>
        <div className="press-releases__content-wrapper">
          {pressReleases.allMdx.nodes.map(pressRelease => (
            <Link key={pressRelease.fields.slug} to={pressRelease.fields.slug} className="press-release">
              <p>
                {pressRelease.frontmatter.title}&nbsp;{">"}
              </p>
              <p className="press-release__date">{pressRelease.frontmatter.date}</p>
            </Link>
          ))}
        </div>
      </div>
    </section>
  )
}

export default PressReleases
