import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"

import PressArticleEntry from "./PressArticleEntry"

import "./PresseKit.scss"

const PresseKit: React.FunctionComponent = () => {
  const images = useStaticQuery(graphql`
    query {
      team: file(
        relativePath: { eq: "presse/equipe-greengo.jpeg" }
      ) {
        childImageSharp {
          fluid(
            maxWidth: 500
            maxHeight: 350
            srcSetBreakpoints: [350, 500, 1000]
          ) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      images: file(
        relativePath: { eq: "presse/plat-greengo.jpeg" }
      ) {
        childImageSharp {
          fluid(
            maxWidth: 500
            maxHeight: 350
            srcSetBreakpoints: [350, 500, 1000]
          ) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      logos: file(relativePath: { eq: "presse/greengo-logo-purple-bg.jpg" }) {
        childImageSharp {
          fluid(
            maxWidth: 500
            maxHeight: 350
            srcSetBreakpoints: [350, 500, 1000]
          ) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <section className="press-kit section-padding">
      <div className="container">
        <h2 className="heading heading--section-title">Logos et images</h2>
        <div className="press-kit__content-wrapper">
          <ul className="press-kit__items">
            <li>
              <PressArticleEntry
                title="LOGOS"
                image={images.logos.childImageSharp}
                uri="https://drive.google.com/drive/folders/1YyRAtGEwHcVYU2IHyvjywF4pyIBhlX9z"
              />
            </li>
            <li>
              <PressArticleEntry
                title="VISUELS ET PHOTOS"
                image={images.images.childImageSharp}
                uri="https://drive.google.com/drive/folders/16YaIXglNctqS3_DKah6d3af8Bdr_2s1M"
              />
            </li>
            <li>
              <PressArticleEntry
                title="EQUIPE"
                image={images.team.childImageSharp}
                uri="/notre-equipe"
              />
            </li>
          </ul>
          <Link
            to="/notre-equipe"
            className="link button button--color-pink"
          >
            Qui sommes nous ?
          </Link>
        </div>
      </div>
    </section>
  )
}

export default PresseKit
