import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"

import "./AboveTheFoldPresse.scss"

const AboveTheFoldPresse: React.FunctionComponent = () => {
  const images = useStaticQuery(graphql`
    query {
      aboutusbg: file(name: { eq: "presse-header-bg" }) {
        childImageSharp {
          fluid(
            maxWidth: 2880
            quality: 100
            srcSetBreakpoints: [800, 1275, 1440, 1920, 2880]
          ) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <section className="above-the-fold-presse section-padding">
      <div className="above-the-fold-presse__background-image">
        <Img
          fluid={images.aboutusbg.childImageSharp.fluid}
          style={{ height: "100%" }}
          loading="eager"
          fadeIn={false}
        />
      </div>
      <div className="above-the-fold-presse__app1 container">
        <div className="above-the-fold-presse__text">
          <div className="">
            <h1 className="heading heading--1">Page presse</h1>
            <p className="paragraph paragraph--white paragraph--header">
              Retrouvez ici nos derniers communiqués, notre kit de presse et
              tous les articles qui parlent de La Consigne GreenGo !
            </p>
          </div>
        </div>
        <div className="above-the-fold-presse__splash">
          <p className="heading heading--3">Contactez nous</p>
          <p>
            <a
              className="link"
              href="mailto:communication@laconsignegreengo.com"
            >
              communication@laconsignegreengo.com
            </a>
          </p>
        </div>
      </div>
    </section>
  )
}

export default AboveTheFoldPresse
