import React from 'react';

import Layout from '../components/Layout';
import AboveTheFoldPresse from '../components/pages/presse/AboveTheFoldPresse';
import PressArticles from '../components/pages/presse/PressArticles';
import PresseKit from '../components/pages/presse/PresseKit';
import PresseLogos from '../components/pages/presse/PresseLogos';
import PressReleases from '../components/pages/presse/PressReleases';

const Presse: React.FunctionComponent = () => {
  return (
    <Layout activePage="presse">
      <AboveTheFoldPresse />
      <PressReleases />
      <PressArticles />
      <PresseLogos />
      <PresseKit />
    </Layout>
  )
};

export default Presse;