import { Link } from "gatsby"
import Img, { GatsbyImageProps } from "gatsby-image"
import React from "react"
import "./PressArticleEntry.scss"
import ExitIcon from "../../../images/shared/external-link-symbol.svg"

export interface IPressArticleEntry {
  image: GatsbyImageProps
  logo?: GatsbyImageProps
  logoWidth?: number
  title: string
  uri: string
}

const isExternalLink = (uri: string) => {
  return uri.startsWith("http")
}
const PressArticleEntry: React.FunctionComponent<IPressArticleEntry> = props => {
  return (
    <a
      href={props.uri}
      {...(isExternalLink(props.uri)
        ? { target: "_blank", rel: "noopener noreferrer" }
        : {})}
      className="press-article-entry__card"
    >
      {props.logo ? (
        <div
          className="press-article-entry__logo"
          style={{ width: props.logoWidth}}
        >
          {typeof props.logo === 'string' ? <img src={props.logo} width={props.logoWidth} /> : <Img {...props.logo} imgStyle={{ objectFit: "contain" }} />}
        </div>
      ) : null}
      <Img {...props.image} className="press-article-entry__image" />
      <div className="press-article-entry__footer">
        {/* if category slug is unknown, display a generic one instead of empty string */}
        <h3 className="press-article-entry__title">{props.title}</h3>
        {isExternalLink(props.uri) && (
          <img
            className="press-article-entry__external-icon"
            src={ExitIcon}
            width={30}
          />
        )}
      </div>
    </a>
  )
}

export default PressArticleEntry
