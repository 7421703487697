import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImageProps } from "gatsby-image"
import React, { useEffect, useState } from "react"
import PressArticleEntry from "./PressArticleEntry"
import "./PressArticles.scss"

const PressArticles: React.FunctionComponent = () => {
  const data: {
    allMdx: {
      nodes: {
        id: string
        fields: { slug: string }
        frontmatter: {
          title: string
          date: string | undefined
          image: {
            childImageSharp: GatsbyImageProps
          }
          logo: {
            childImageSharp: GatsbyImageProps
            publicURL: string
          }
          logoWidth: number | undefined
          url: string
        }
      }[]
    }
  } = useStaticQuery(graphql`
    query {
      allMdx(
        limit: 18
        sort: { fields: frontmatter___date, order: DESC }
        filter: { frontmatter: { templateKey: { eq: "ArticlePresse" } } }
      ) {
        nodes {
          id
          fields {
            slug
          }
          frontmatter {
            image {
              childImageSharp {
                fluid(
                  maxWidth: 500
                  maxHeight: 350
                  srcSetBreakpoints: [350, 500, 1000]
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            logo {
              childImageSharp {
                fluid(maxWidth: 150, srcSetBreakpoints: [150, 300]) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
              publicURL
            }
            logoWidth
            title
            date(fromNow: true, locale: "FR")
            url
          }
        }
      }
    }
  `)
  const pressArticles = data.allMdx.nodes
  const [maxIndexToDisplay, setMaxIndexToDisplay] = useState(2)
  const [displayViewMoreButton, setDisplayViewMoreButton] = useState(false)
  const displayMoreArticles = () => setMaxIndexToDisplay(maxIndexToDisplay + 3)
  useEffect(() => {
    if (pressArticles.length > 3) {
      setDisplayViewMoreButton(true)
    }
  }, [])
  useEffect(() => {
    if (maxIndexToDisplay >= pressArticles.length) {
      setDisplayViewMoreButton(false)
    }
  }, [maxIndexToDisplay])
  return (
    <section className="press-articles section-padding">
      <div className="container">
        <h2 className="heading heading--section-title">Ils parlent de nous</h2>
        <div className="press-articles__content-wrapper">
          <ul className="press-articles__articles">
            {pressArticles.map((pressArticle, index) => (
              <li
                key={pressArticle.id}
                className={index > maxIndexToDisplay ? "d-none" : ""}
              >
                <PressArticleEntry
                  title={pressArticle.frontmatter.title}
                  image={pressArticle.frontmatter.image.childImageSharp}
                  uri={pressArticle.frontmatter.url}
                  logo={
                    pressArticle.frontmatter.logo?.childImageSharp ||
                    pressArticle.frontmatter.logo?.publicURL
                  }
                  logoWidth={pressArticle.frontmatter.logoWidth}
                />
              </li>
            ))}
          </ul>
          {displayViewMoreButton && (
            <button
              onClick={displayMoreArticles}
              className="link button button--color-pink"
            >
              Voir plus
            </button>
          )}
        </div>
      </div>
    </section>
  )
}

export default PressArticles
